<template>
	<div class="list-invoices">
		<div class="list-invoices__heading">
		</div>

		<table-list
			:items="invoices"
		>
			<template slot="header">
				<span
					:class="`invoices-header-item invoices-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					{{ col }}
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`invoices-item invoices-item--${key}`"
					:key="`item_${key}`"
					:to="{ name: 'user', params: { uid: item.uid } }"
					v-for="(col, key) in columns"
				>
					{{ item[key] }}
				</router-link>
			</template>
		</table-list>
	</div>
</template>

<script>
import TableList from '@/components/TableList'

export default {
	name: 'Listinvoices',
	components: {
		TableList,
	},
	props: {
		invoices: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		columns: {
			date: 'Date',
			number: 'Invoice #',
			amount: 'Amount',
			status: 'State',
		},
	}),

}
</script>

<style scoped lang="scss">
.list-invoices {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: 1fr 2fr 2fr 1fr;

			@include modules.media-query('phone') {
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	.invoices-header-item {
		@include modules.fontSize(14px);
		font-weight: 600;
		padding: 1em 1.5em;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.invoices-item {
		color: modules.color_('text', 'dark');
		font-weight: 500;
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.5em 1em;
		}

	}
}
</style>
