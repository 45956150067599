<template>
	<div class="partial partial--invoices invoices">
		<div class="partial__heading">
			<ion-icon name="people-circle-outline" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Billing &amp; Invoices</h1>
		</div>

		<div class="partial__body invoices__body">
			<list-invoices :invoices="invoices" />
		</div>
	</div>
</template>

<script>
import ListInvoices from '@/components/ListInvoices'

export default {
	name: 'PartialInvoices',
	components: {
		ListInvoices,
	},
	metaInfo: () => ({
		title: `Billing & Invoices`,
	}),
	data: () => ({
		invoices: [
			{
				date: '07/09/2020',
				number: '1004',
				amount: '$1,239.00',
				status: 'Pending',
			},
			{
				date: '07/09/2020',
				number: '1003',
				amount: '$1,239.00',
				status: 'Paid',
			},
			{
				date: '07/09/2020',
				number: '1002',
				amount: '$1,239.00',
				status: 'Paid',
			},
		],
	}),
}
</script>

<style scoped lang="scss">

</style>
